/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Button, Text } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1so983 --style3 --full" anim={""} name={"einleitung"} animS={"3"} border={""} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--1 pt--25 flex--center" anim={null} style={{"maxWidth":1350}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--86 w--600 lh--1" style={{"maxWidth":700}} content={"<span style='color: white;'>Augenklinik</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--26" content={"<span style='color: white;'>Am besten bewertete Klinik im Brno</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--22 mt--25" content={"Arzttermine online buchen"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"information"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pb--10 pt--10 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1350}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":596}} content={"Professionelle Pflege um Ihre Gesundheit"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen."}>
              </Text>

              <Subtitle className="subtitle-box lh--16" content={"+49 797 811 2X05<br>info@vase-stranky.com"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":400}}>
              
              <Text className="text-box w--600" content={"Montag"}>
              </Text>

              <Subtitle className="subtitle-box mt--02" content={"7:00 — 11:00, 13:00 — 15:00"}>
              </Subtitle>

              <Text className="text-box w--600 mt--10" style={{"maxWidth":400}} content={"Diesntag"}>
              </Text>

              <Subtitle className="subtitle-box mt--02" content={"7:00 — 11:00"}>
              </Subtitle>

              <Text className="text-box w--600 mt--10" content={"Mittwoch"}>
              </Text>

              <Subtitle className="subtitle-box mt--02" content={"7:00 — 11:00, 13:00 — 15:00"}>
              </Subtitle>

              <Text className="text-box w--600 mt--10" content={"Donnerstag"}>
              </Text>

              <Subtitle className="subtitle-box mt--02" content={"7:00 — 11:00"}>
              </Subtitle>

              <Text className="text-box w--600 mt--10" content={"Freitag"}>
              </Text>

              <Subtitle className="subtitle-box mt--02" content={"7:00 — 11:00, 13:00 — 15:00"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"dienstleistungen"} layout={"l1"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Title className="title-box" style={{"maxWidth":299}} content={"Umfassende Augenuntersuchung"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Button className="btn-box btn-box--shape5" content={"Befehl"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Title className="title-box" style={{"maxWidth":299}} content={"Brillen-Rezept"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Button className="btn-box btn-box--shape5" content={"Befehl"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Title className="title-box" style={{"maxWidth":299}} content={"Katarakt Entfernung"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Button className="btn-box btn-box--shape5" content={"Befehl"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"information-2"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex el--3" style={{"maxWidth":1350}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--26" content={"✅&nbsp; &nbsp;All insurance companies"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--26" content={"✅&nbsp; &nbsp;Modern equipment"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--26" content={"✅&nbsp; &nbsp;Accepting new patients"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1so983 pb--80 pt--80" name={"kontakt"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Arzttermine online buchen</span>"}>
              </Title>

              <Button className="btn-box btn-box--shape5 fs--22" content={"Befehl"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":476}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Mudr. Peter Müller</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">Oeder Weg 22<br>+49 797 811 2X05<br>info@vase-stranky.com<br>Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}